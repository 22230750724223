import React from "react";
import { useRef } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import * as s from "./contact.module.css";
import { StaticImage } from "gatsby-plugin-image";
import axios from "axios";

// const configSlack = {
//   baseURL: "https://hooks.slack.com/services/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",
//   headers: { "Content-Type": "application/json" },
//   method: "post",
//   timeout: 10000,
// };

const configHubSpot = {
  baseURL: "https://webhook.site/427d7f1c-5c86-498c-b25a-4f5f31afd6a2",
  headers: { "Content-Type": "application/json" },
  method: "post",
  timeout: 10000,
};
// const instance = axios.create(configSlack);
const instance = axios.create(configHubSpot);

const ContactForm = ({ title, titleStyle }) => {
  const form = useRef();
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [businessName, setBusinessName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [messageSent, setMessageSent] = React.useState(false);

  const [
    iConcentToReceiveTextNotifications,
    setIConcentToReceiveTextNotifications,
  ] = React.useState(true);
  const [contactDate, setContactDate] = React.useState("");
  const [contactTime, setContactTime] = React.useState("");

  const handleSubmit = event => {
    event.preventDefault();
    const queryParams = JSON.parse(sessionStorage.getItem("queryParams"));
    const lines = [];
    try {
      for (var x in queryParams) {
        lines.push(`*${x}:* ${queryParams[x]}`);
      }
    } catch (e) {}

    const payloadForm = {
      event: "lead",
      source: "orders.co",
      utm_source: queryParams?.utm_source || "",
      name: !!name ? name : "",
      email: !!email ? email : "",
      phone: !!phone ? phone : "",
      businessName: !!businessName ? businessName : "",
      iConcentToReceiveTextNotifications: iConcentToReceiveTextNotifications
        ? "Yes"
        : "No",
      contactDate: iConcentToReceiveTextNotifications ? "" : contactDate,
      contactTime: iConcentToReceiveTextNotifications ? "" : contactTime,
    };

    const payload = {
      ...payloadForm,
      details: lines.join("\n"),
    };
    instance
      .request({ data: JSON.stringify(payload) })
      .then(function (response) {
        // handle success
        console.log(payload);
        console.log("Form1 was submitted successfully");
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
        window.dataLayer.push({ ...payloadForm });
        setName("");
        setEmail("");
        setBusinessName("");
        setPhone("");
        setMessageSent(true);
      });

    // instance1
    //   .request({ data: JSON.stringify(payload) })
    //   .then(function (response) {
    //     // handle success
    //     console.log("Form2 was submitted successfully");
    //   })
    //   .catch(function (error) {
    //     // handle error
    //     console.log(error);
    //   })
  };

  const available_time = [
    "09:00 am",
    "09:15 am",
    "09:30 am",
    "09:45 am",
    "10:00 am",
    "10:15 am",
    "10:30 am",
    "10:45 am",
    "11:00 am",
    "11:15 am",
    "11:30 am",
    "11:45 am",
    "12:00 pm",
    "12:15 pm",
    "12:30 pm",
    "12:45 pm",
    "13:00 pm",
    "13:15 pm",
    "13:30 pm",
    "13:45 pm",
    "14:00 pm",
    "14:15 pm",
    "14:30 pm",
    "14:45 pm",
    "15:00 pm",
    "15:15 pm",
    "15:30 pm",
    "15:45 pm",
    "16:00 pm",
    "16:15 pm",
    "16:30 pm",
    "16:45 pm",
    "17:00 pm",
    "17:15 pm",
    "17:30 pm",
    "17:45 pm",
    "18:00 pm",
  ];

  const titleClasses = [];
  switch (titleStyle) {
    case "small":
      titleClasses.push(s.small);
      break;
    default:
      break;
  }

  const titleDefault = (
    <>
      Build Your
      <br /> Commission Free
      <br /> Website Today
    </>
  );
  return (
    <section className={s.contactForm} id="contact-form">
      <div className="container">
        <div className={s.formContent}>
          <div className={s.formText}>
            <h2 className={titleClasses.join(" ")}>
              {!!title ? title : titleDefault}
            </h2>
            <StaticImage
              placeholder="none"
              loading="eager"
              alt="young_man"
              src="../../images/form/young_curly_man.png"
              className="d-md-none"
              style={{ maxWidth: "180px" }}
            />
          </div>
          <div className={s.formShortcode}>
            {messageSent === false && (
              <div
                role="form"
                className="wpcf7"
                id="wpcf7-f149-o1"
                lang="ru-RU"
                dir="ltr"
              >
                <div className="screen-reader-response">
                  <p role="status" aria-live="polite" aria-atomic="true"></p>
                  <ul></ul>
                </div>
                <form
                  onSubmit={handleSubmit}
                  ref={form}
                  className="wpcf7-form init"
                  data-status="init"
                >
                  <div>
                    <label>
                      Full Name
                      <br />
                      <span className="wpcf7-form-control-wrap customer-name">
                        <input
                          type="text"
                          name="name"
                          value={name}
                          onChange={e => setName(e.target.value)}
                          size="40"
                          required
                          placeholder="Jameson Pike"
                        />
                      </span>
                    </label>
                  </div>
                  <div>
                    <label>
                      Email
                      <br />
                      <span className="wpcf7-form-control-wrap customer-email">
                        <input
                          type="email"
                          name="email"
                          size="40"
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                          required
                          placeholder="Email Address"
                        />
                      </span>
                    </label>
                  </div>
                  <div>
                    <label>
                      Business Name
                      <br />
                      <span className="wpcf7-form-control-wrap customer-business">
                        <input
                          type="text"
                          name="businessName"
                          size="40"
                          value={businessName}
                          onChange={e => setBusinessName(e.target.value)}
                          required
                          placeholder="Business Name"
                        />
                      </span>
                    </label>
                  </div>
                  <div>
                    <label>
                      {" "}
                      Phone Number
                      <span className="wpcf7-form-control-wrap customer-phone">
                        <PhoneInput
                          defaultCountry="US"
                          placeholder="+1234567890"
                          value={phone}
                          onChange={setPhone}
                          className="mb-0"
                          required
                          name="phone"
                        />
                      </span>
                    </label>
                  </div>
                  <div className="mb-3">
                    <label
                      className="d-flex align-items-center cursor-pointer"
                      style={{ gap: "15px" }}
                    >
                      <input
                        type="checkbox"
                        className="cursor-pointer"
                        onChange={e => {
                          setIConcentToReceiveTextNotifications(
                            e.target.checked
                          );
                        }}
                        checked={iConcentToReceiveTextNotifications}
                      />
                      Text me with updates about this meeting
                    </label>
                  </div>

                  <label>
                    <input
                      type="submit"
                      value="GET STARTED FOR FREE"
                      className="wpcf7-form-control has-spinner wpcf7-submit"
                    />
                    <span className="wpcf7-spinner"></span>
                  </label>
                  <div
                    className="wpcf7-response-output"
                    aria-hidden="true"
                  ></div>
                </form>
              </div>
            )}
            {messageSent === true && (
              <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
                <StaticImage
                  placeholder="none"
                  loading="eager"
                  alt="thank_you"
                  src="../../images/thank_you.png"
                />
                <div className={`${s.thankYou} text-center`}>
                  <span>
                    Thank you!
                    <br /> You are all set!
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
