import React from "react";
import * as s from "./single-device.module.css";
import TrialButton from "./../trialButton/trialButton";

const SingleDevice = ({ title, h1, subtitle, image, scrollToForm }) => {
  return (
    <>
      <section className={s.singleDeviceBlock}>
        <div className="container">
          <div className="row justify-content-xl-center">
            <div className="col-12 col-xl-11">
              {!h1 && <h2 className={`${s.title} title text-center`}>{title}</h2>}
              {!!h1 && <h1 className={`${s.title} title text-center`}>{title}</h1>}
            </div>
            {!!subtitle && (
              <div className={`${s.description} col-12 text-center"`}>
                {subtitle}
              </div>
            )}
            <div className={`${s.imageWrapper} col-12`}>
              <div className="d-flex align-items-center justify-content-center">
                <div className={s.singleDeviceWrapper}>{image}</div>
              </div>
            </div>
            <div className="col-12 text-center">
              <TrialButton
                scrollToForm={scrollToForm}
                classes={[
                  "trial-button bg-orange cursor-pointer d-inline-flex align-items-center",
                ]}
              >
                GET STARTED FOR FREE
              </TrialButton>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SingleDevice;
