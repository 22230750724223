import { graphql } from "gatsby";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import iconAdv1 from "../images/IndependentRestaurants/icon-adv-1.png";
import iconAdv2 from "../images/IndependentRestaurants/icon-adv-2.png";
import iconAdv3 from "../images/IndependentRestaurants/icon-adv-3.png";
import ContactForm from "../components/contactForm/form";
import Faq from "../components/faq/faq";
import Features from "../components/features/features";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import ImageText from "../components/imageText/imageText";
import Integrations from "../components/integrations/integrations";
import News from '../components/news/news';
import Seo from "../components/seo";
import SingleDevice from "../components/singleDevice/singleDevice";
import Testimonials from "../components/testimonials/testimonials";
import Top from "../components/top/top";

import testimonialsState from "./../utils/testimonials";

// markup
const IndependentRestaurantsPage = ({ location, data }) => {
  const formRef = React.useRef(null);
  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const topProps = {
    layout: 1,
    scrollToForm: scrollToForm,
    title: "Many Ordering Apps? Simple Solution For Independent Restaurants",
    subtitle: `Orders.co was built from the ground up to support restaurants of varying sizes.
            Our tools help cut costs, save employee time, and improve workflow.
            All while being scalable to match your business’s needs.`,
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="ordering product app"
        src="../images/IndependentRestaurants/ordering-app-product.png"
        className="mw-100"
      />
    ),
  };

  const features = [
    {
      bg: "#F1F7FF",
      icon: iconAdv1,
      title: "Personalized Website",
      description:
        "Create your very own ordering website in no time with just your menu.",
    },
    {
      bg: "#DEFFEE",
      icon: iconAdv2,
      title: "Direct Ordering",
      description:
        "Cut out the middlemen and give your customers an easy way to order directly from you without the commission!",
    },
    {
      bg: "#FFF7E3",
      icon: iconAdv3,
      title: "Reliable Delivery ",
      description:
        "Offer delivery on all website orders with in-house staff or through our delivery partners.",
    },
  ];

  const singleDeviceProps = {
    title: `Easily manage your restaurant's online orders with a single device`,
    image: (
      <>
        <StaticImage
          placeholder="none"
          loading="eager"
          alt="Restaurant Menu Management"
          src="../images/menu-management_tablet.png"
          className="mw-100"
        />
      </>
    ),
    scrollToForm: scrollToForm,
  };

  const testimonialsProps = {
    layout: 1,
    items: [
      testimonialsState.la_fonda,
      testimonialsState.pulp_juice,
      testimonialsState.tnt_aloha_cafe,
    ],
    title: "Hear From Our Customers",
    tag: "Testimonials",
  };

  const imageTextProps1 = {
    layout: "equal-big",
    title:
      "Get a commission-free ordering website and increase revenue with direct customer sales",
    titleStyle: "smaller",
    imagePosition: "right",
    description: `Delivery apps have taken takeout to a whole new level of convenience, but charge a premium
            for that service. Studies show that enabling customers to order directly from a restaurant,
            without paying 30% more, are increasingly likely to do so.`,
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="ordering website and menu"
        src="../images/IndependentRestaurants/Ordering-website.png"
      />
    ),
  };

  const imageTextProps2 = {
    layout: "equal-big",
    title: "Reliable Delivery feature",
    titleStyle: "smaller",
    imagePosition: "left",
    description: `Generate detailed reports across multiple ordering channels with just the click of a button.
            Remove the hassle of jumping between multiple dashboards to access the information you need.`,
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="food delivery"
        src="../images/IndependentRestaurants/food-delivery.png"
      />
    ),
  };

  const imageTextProps3 = {
    layout: "equal-big",
    title: "QR Code Menu",
    titleStyle: "smaller",
    imagePosition: "right",
    description: `We help you create a digital menu that features QR codes that can be displayed on menus,
            in-store, and on customer receipts. When scanned, these QR codes will direct customers straight
            to your ordering website where they can browse your menu and place orders or pickup or delivery.`,
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="QR Code"
        src="../images/IndependentRestaurants/qr-code.png"
      />
    ),
  };

  const questions = [
    {
      question: `How long does it take to set up Orders.co?`,
      answer: `Orders.co’s integration and setup takes around 1-2 weeks, depending on the current workload.
                For a more detailed estimate, contact a sales representative.`,
    },
    {
      question: `Which delivery platforms are supported by Orders.co?`,
      answer: `Currently Orders.co supports Grubhub, Postmates, UberEats, and DoorDash. With many more on
                the way. Restaurant partners are also encouraged to request new platform integrations.`,
    },
    {
      question: `How much does Orders.co setup cost?`,
      answer: `Orders.co setup fee is $100.`,
    },
    {
      question: `How does Orders.co process website transactions?`,
      answer: `Orders.co has partnered with Stripe for all online credit card processing.
                All restaurant partners are required to create and pair.`,
    },
    {
      question: `Does Orders.co offer a delivery service for web orders?`,
      answer: `Yes, Orders.co has partnered with UberEats to offer delivery for restaurants that do not have
                delivery staff. The delivery fee will be charged directly to the customer at checkout.
                There is also an option for In-House delivery for restaurants with existing staff.`,
    },
  ];

  return (
    <>
      <Seo
        title={"Independent Restaurants"}
        description={""}
        pageSeo={data.wpPage}
        location={location}
      />
      <Header scrollToForm={scrollToForm}></Header>
      <main>
        <Top {...topProps} />
        <Features title="Top Benefits" items={features}></Features>
        <SingleDevice {...singleDeviceProps} />
        <Testimonials {...testimonialsProps}></Testimonials>
        <ImageText {...imageTextProps1} />
        <ImageText {...imageTextProps2} />
        <ImageText {...imageTextProps3} />
        <div
          className="bg-mobile"
          style={{
            background:
              "linear-gradient(180deg, #FFF8ED 0%, rgba(255, 248, 237, 0.5) 100%)",
          }}
        >
          <Integrations scrollToForm={scrollToForm}></Integrations>
          <News items={data.allWpPost} />
          <Faq items={questions} />
          <div ref={formRef}>
            <ContactForm></ContactForm>
          </div>
        </div>
      </main>
      <Footer scrollToForm={scrollToForm}></Footer>
    </>
  );
};

export const query = graphql`
  query {
    wpPage(slug: { eq: "independent-restaurants" }) {
      title
      id
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
    }
    allWpPost(
      sort: {fields: [date], order: DESC}
      limit: 3
      skip: 0
      filter: {categories: {nodes: {elemMatch: {slug: {eq: "independent-restaurants"}}}}}
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        featuredImage {
          node {
            gatsbyImage(width: 600,quality: 80)
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90, placeholder: TRACED_SVG, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`;

export default IndependentRestaurantsPage;
